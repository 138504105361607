import { render, staticRenderFns } from "./EditProfile.vue?vue&type=template&id=709aafe7&scoped=true&"
import script from "./EditProfile.vue?vue&type=script&lang=ts&"
export * from "./EditProfile.vue?vue&type=script&lang=ts&"
import style0 from "./EditProfile.vue?vue&type=style&index=0&id=709aafe7&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "709aafe7",
  null
  
)

export default component.exports